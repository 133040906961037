module.exports = {
    init: function () {
        let test = document.querySelector("form .bgchoice");
        if(test) {
            settings.bgChoice.init();
        }
    },
    bgChoice: {
        init: function () {
            let bglist = document.querySelectorAll('.bgchoice');
            for(let i = 0; i < bglist.length; i++) {
                bglist[i].addEventListener('click', settings.bgChoice.select);
            }
        },
        select: function (e) {
            let choiceInput = document.querySelector("#profile_presetBg");
            choiceInput.value = e.currentTarget.querySelector('input').value;
        }
    }
};