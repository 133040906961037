module.exports = {
    cookiesSet: false,
    devmode: true,
    init: function() {
        // 1 - vérifier si des instructions ont été enregistrées

        RGPD.doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack;
        RGPD.cookiesSet = RGPD.getConsentCookie();

        // 2 - en l'absence d'instructions, ou s'il en manque, afficher le bandeau
        if(!RGPD.allSet())
            $("#rgpd").addClass('active');
        // 2b - si instructions, et si on est sur la page RGPD, mettre à jour les boutons
        RGPD.updateButtons();

        // 3 - gérer les boutons de réglages
        $(".rgpd-btn").on('click', RGPD.buttonClick);
        $(".rgpd-enable-all").on('click', RGPD.enableAll);
        $(".rgpd-disable-all").on('click', RGPD.disableAll);

        // 4 - enfin, selon les valeurs récupérées, activer les cookies autorisés.
        RGPD.activation();
    },
    // Activation de tous les services pour lesquels le consentement a été obtenu.
    activation: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(cookie.isSet && cookie.allowed && !cookie.enabled)
                cookie.enable();
        }
    },
    // clic sur un des boutons de réglage (classe .rgpd-btn)
    buttonClick: function(e) {
        $(e.currentTarget).parent().find('button').removeClass('active');
        $(e.currentTarget).addClass('active');
        RGPD.updateSettings();
        RGPD.setConsentCookie();
    },
    setAll: function(action) {
        $(".rgpd-btn").removeClass('active');
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            $("button[data-item='"+prop+"'][data-action='"+action+"']").addClass('active');
            cookie.isSet = true;
            cookie.allowed = (action == "enable");
        }
        RGPD.setConsentCookie();
        $("#rgpd").removeClass('active');
    },
    enableAll: function() {
        RGPD.setAll('enable');
    },
    disableAll: function() {
        RGPD.setAll('disable');
    },
    // mise à jour des réglages selon les états des boutons
    updateSettings: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if($("button[data-item='"+prop+"'].active").length > 0) {
                cookie.isSet = true;
                cookie.allowed = $("button[data-item='"+prop+"'].active").attr('data-action') == "enable";
            }
        }
        if(RGPD.buttonsAllSet()) {
            RGPD.activation();
            $("#rgpd").removeClass('active');
        }
    },
    // Mise à jour des boutons selon les cookies récupérés
    updateButtons: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(cookie.isSet) {
                let dA = cookie.allowed ? 'enable' : 'disable';
                $("button[data-item='"+prop+"'][data-action='"+dA+"']").addClass('active');
            }
        }
    },
    // Vérifie l'existence des cookies de consentement et récupère leur état.
    getConsentCookie: function(){
        let raw = Cookies.get('rgpd');
        if(typeof raw !== "undefined") {
            let consentCookie = JSON.parse(Cookies.get('rgpd')) ;
            for (let prop in consentCookie) {
                if(typeof RGPD.cookies[prop] !== "undefined") {
                    RGPD.cookies[prop].isSet = true;
                    RGPD.cookies[prop].allowed = consentCookie[prop];
                }
            }
            return true;
        }
        else
            return false;
    },
    // enregistre les réglages courants des cookies de consentement
    setConsentCookie: function() {
        let jsonCookie = {};
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(cookie.isSet)
                jsonCookie[prop] = cookie.allowed;
        }
        let rawcookie = JSON.stringify(jsonCookie);
        if(rawcookie !== "{}")
            Cookies.set('rgpd', rawcookie, { expires: 395 });
    },
    // Pour chaque service : état du cookie de consentement et procédure d'activation.
    cookies: {
        /*
        "nomduservice": {
            isSet : le cookie existe.
            allowed: le cookie vaut true (l'utilisateur accepte son activation)
            enabled: true quand le service a été activé via la fonction enable()
            enable: la fonction en question
            disable: quand le service propose un processus de désactivation, cette fonction est appelée quand le user clique sur "refuser".
        }
         */
        "matomo" : {
            isSet : false,
            allowed : false,
            enabled: false,
            enable: function() {
                if(!RGPD.devmode) {
                    var _paq = window._paq = window._paq || [];
                    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                        var u="//stats.f02-fm.com/";
                        _paq.push(['setTrackerUrl', u+'matomo.php']);
                        _paq.push(['setSiteId', '1']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                    })();
                }
                else {
                    console.log('MATOMO script added to header')
                }
                RGPD.cookies.matomo.enabled = true;
            },
            disable: function() {
                if(!RGPD.devmode) {
                }
                RGPD.cookies.matomo.enabled = false;
            }
        }
    },
    allSet: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(!cookie.isSet)
                return false;
        }
        return true;
    },
    buttonsAllSet: function() {
        for (let prop in RGPD.cookies) {
            let cookie = RGPD.cookies[prop];
            if(!cookie.isSet)
                return false;
        }
        return true;
    }
};