module.exports = {
    init: function () {
        //Version mobile -------------------------------------------------------------------------------------------------

        let toggle_tools = document.getElementById("tools_button");
        let mobile_tools = document.getElementById("tools");
        let close_tools = document.getElementById("close_tools");

        let toggle_actions = document.getElementsByClassName("m-actions-btn");
        for(let i = 0; i < toggle_actions.length; i++) {
            toggle_actions[i].addEventListener("click", function(e) {
                let target = e.currentTarget;
                let _li = target.parentNode;
                console.log(target, _li);
                _li.classList.toggle("open");
            })
        }

        if(!toggle_tools)
            return;

        let toggle = function(target){
            if (target.classList.contains('open')){
                target.classList.remove('open');
            }  else{
                target.classList.add('open')
            }
        };

        toggle_tools.addEventListener('click', function (e) {
            toggle_tools.style.animation = "bump .3s ease-in-out";
            toggle(mobile_tools);
        });

        close_tools.addEventListener('click', function () {
            toggle(mobile_tools);
        })
    }
};