module.exports = {
    opening: false,
    init: function() {
        let tooltipA = document.querySelectorAll(".tooltipmenucontainer > a");
        for(let i = 0; i < tooltipA.length; i++) {
            tooltipA[i].addEventListener('click', tooltips.openMenu);
        }
        document.querySelectorAll('body')[0].addEventListener('click', tooltips.closeAll);
        let mMenuContainer = document.querySelector("#mobilemenucontainer");
        let aOptions = document.querySelector("#mobilemenucontainer a.options");
        if(aOptions) {
            document.querySelector("#mobilemenucontainer a.options").addEventListener("click", function(e) {
                e.preventDefault();
                tooltips.closeAll();
            });
        }
        if(mMenuContainer) {
            mMenuContainer.addEventListener("click", function(e) {
                if(e.target === e.currentTarget)
                    tooltips.closeAll();
            });
        }
    },

    openMenu: function(e) {
        e.preventDefault();
        let tooltip = e.currentTarget.parentNode.querySelectorAll(".tooltipmenu")[0];
        let mobilemenu = document.getElementById("mobilemenucontainer");
        let mMenuOverlay = document.getElementById("mobilemenuoverlay");
        let isOpen = tooltip.classList.contains('open');
        tooltips.closeAll();
        if(!isOpen) {
            tooltips.opening = true;
            if(tooltip)
                tooltip.classList.add('open');
            if(mobilemenu)
                mobilemenu.classList.add('open');
            if(mMenuOverlay)
                mMenuOverlay.classList.add("open");

        }
        setTimeout(function() {tooltips.opening = false;}, 200);
    },

    closeAll: function(e) {
        if(tooltips.opening)
            return;
        let tlist = document.querySelectorAll(".tooltipmenu");
        for(let i = 0; i < tlist.length; i++)
            tlist[i].classList.remove('open');
        let mobilemenu = document.getElementById("mobilemenucontainer");
        let mMenuOverlay = document.getElementById("mobilemenuoverlay");
        if(mobilemenu)
            mobilemenu.classList.remove('open');
        if(mMenuOverlay)
            mMenuOverlay.classList.remove("open");
    }
};