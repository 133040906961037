module.exports = {
    active: false,
    init: function () {
        let plansContainer = document.querySelector(".table-plans");
        if(plansContainer) {
            this.active = true;
            let planList = document.querySelectorAll(".col-plan");
            for(let i = 0; i < planList.length; i++) {
                let label = planList[i].querySelector(".specs label");
                let buttons = planList[i].querySelector(".buttons");
                if(label) {
                    label.addEventListener("click", plans.labelClick);
                    buttons.querySelector(".m").classList.add("visible");
                }
                else
                    buttons.querySelector(".y").classList.add("visible");
            }
        }
    },
    labelClick: function(e) {
        if(e.target === e.currentTarget) {
            let _buttons = e.currentTarget.parentNode.parentNode.querySelectorAll(".buttons a");
            for(let i = 0; i < _buttons.length; i++)
                _buttons[i].classList.remove("visible");
            e.currentTarget.classList.toggle("active");
            let _show = (e.currentTarget.classList.contains("active")) ? ".y" : ".m";
            e.currentTarget.parentNode.parentNode.querySelector(_show).classList.add("visible");
        }
    }
}