module.exports = {
    messages: null,
    init: function () {
        notifications.messages = document.getElementById('messages');
        if (notifications.messages && notifications.messages.firstElementChild !== null) {
            notifications.show();
        }

    },
    show: function() {
        if (notifications.messages.firstElementChild !== null) {
            notifications.messages.style.display = "block";
            notifications.messages.style.animation = "fade 5s";

            setTimeout(function () {
                notifications.messages.style.display = "none";
            }, 5000)
        }
    }
};