module.exports = {
    init: function() {
        dirs.remove.init();
        dirs.rename.init();
    },
    remove: {
        init: function() {
            let deleteBtns = document.querySelectorAll('.folder_suppresser');
            for(let i = 0; i < deleteBtns.length; i++) {
                deleteBtns[i].addEventListener('click', dirs.remove.click);
            }
        },
        click: function(e) {
            //e.preventDefault();
            let goAhead = confirm('Envoyer le répertoire dans la corbeille ?');
            if(!goAhead)
                e.preventDefault();
            return goAhead;
        },
    },
    rename: {
        isSelected: false,
        init: function() {
            let renameBtns = document.querySelectorAll('.renameBtn');
            for(let i = 0; i < renameBtns.length; i++) {
                renameBtns[i].addEventListener('click', dirs.rename.click);
            }
        },
        click: function(e) {
            let oldId = dirs.rename.unselect();
            let row = e.currentTarget.parentNode.parentNode;
            let newId = row.dataset.id;
            console.log(oldId, newId);
            if(oldId != newId)
                dirs.rename.select(row);
        },
        select: function(row) {
            row.querySelector('form').style = "display: flex;";
            row.querySelector('a.folder_item, a.file_item').style = "display: none;";
            row.querySelector('form').focus();
            dirs.rename.isSelected = row.dataset.id;
        },
        unselect: function() {
            let rows = document.querySelectorAll('.directories .foldertype, .files .filetype');
            for(let i = 0; i < rows.length; i++) {
                rows[i].querySelector('form').style = "display: none;";
                rows[i].querySelector('a.folder_item, a.file_item').style = "display: flex;";
            }
            let oldId = dirs.rename.isSelected;
            dirs.rename.isSelected = false;
            return oldId;
        }
    }


};