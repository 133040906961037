module.exports = {
    $container: null,
    currentFile: null,
    init: function() {
        this.$container = $("#popin-tree-container");
        this.$container.on('click', function(e) {
            if(e.target == e.currentTarget)
                filemover.hide();
        });
        let movelinks = document.querySelectorAll("a.action.move");
        for(let i = 0; i < movelinks.length; i++) {
            $(movelinks[i]).on('click', function (e) {
                filemover.updateCurrentFile($(e.currentTarget));
                filemover.treeInit($(e.currentTarget).parent().parent().attr("data-id"));
                filemover.show();
            });
        }
    },
    show: function() {
        this.$container.css("display", "flex");
        this.$container.animate({opacity: 1});
    },
    hide: function() {
        this.$container.hide();
    },
    updateCurrentFile: function($movelink) {
        let txtid = $movelink.parent().parent().attr("data-id");
        let id = txtid.replace("f-", "");
        this.currentFile = {"name": $movelink.parent().parent().attr("data-currentName"), "id": id};
        this.$container.find("div.header span").html(this.currentFile.name);
        console.log(this.$container.find("div.header span"));
        let treelinks = document.querySelectorAll("#popin-tree-container .body li a");
        for(let i = 0; i < treelinks.length; i++) {
            $tl = $(treelinks[i]);
            let dirslug = $tl.attr('data-slug');
            $tl.attr("href", $tl.attr("href") + "files/move/" +id + "/" + dirslug);
        }
    },
    treeInit: function(currentFile) {
        console.log('treeInit', this.currentFile);
    }
};